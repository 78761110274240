import { PrinterIcon } from "@heroicons/react/20/solid";
import { useMemo, useState } from "react";
import { PrimaryButton } from "../../components/buttons";
import { PrintContainer } from "../../components/print-container";
import { PrintTable } from "../../components/printable-table";
import {
  DepartmentFilter,
  HideDisabledFilter,
  SearchField,
  SoldInFilter,
  VendorNameFilter,
} from "../../components/product-table/components";
import {
  Actions,
  ItemCode,
  LastReceived,
  MarginPercentage,
  OrderLot,
  ProductName,
  QOHCases,
  Reorder,
  Size,
  Type,
  VendorName,
  getCasesSoldColumn,
} from "../../components/product-table/product-column-definitions";
import { RowRenderer } from "../../components/product-table/row-renderer";
import { Toolbar } from "../../components/toolbar";
import { VirtualizedTable } from "../../components/virtualized-table";
import type { ColumnDefinition } from "../../components/virtualized-table/column-definition";
import { multiSort } from "../../components/virtualized-table/column-sort";
import { ProductTableContext } from "../../contexts/product-table-context";
import type { Product } from "../../data/product";
import {
  type Filters,
  useFilteredProducts,
  useSortedProducts,
} from "../../hooks/products";

const defaultFilters: Filters = { soldIn: 365 };

export function PopularityReport({ products }: { products: Product[] }) {
  const { filters, setFilters, filteredProducts } = useFilteredProducts(
    products,
    defaultFilters,
  );
  const casesSoldColumn = useMemo(
    () => getCasesSoldColumn(filters.soldIn || 365),
    [filters],
  );

  const makeSort = (casesSoldColumn: ColumnDefinition<Product>) => {
    return multiSort([
      {
        column: casesSoldColumn,
        direction: "desc",
      },
    ]);
  };

  const sortedProducts = useSortedProducts(
    filteredProducts,
    makeSort(casesSoldColumn),
  );

  const columns = useMemo(() => {
    return [
      VendorName,
      ItemCode,
      ProductName,
      Type,
      Size,
      OrderLot,
      casesSoldColumn,
      QOHCases,
      LastReceived,
      Reorder,
      MarginPercentage,
      Actions,
    ];
  }, [casesSoldColumn]);

  const [printing, setPrinting] = useState(false);

  return (
    <>
      <ProductTableContext.Provider
        value={{ filters, setFilters, filteredProducts }}
      >
        <Toolbar>
          <HideDisabledFilter />
          <VendorNameFilter products={products} />
          <DepartmentFilter products={products} />
          <SoldInFilter />
          <SearchField />
          <PrimaryButton onClick={() => setPrinting(true)}>
            <PrinterIcon className="h-5 w-5" aria-hidden="true" />
          </PrimaryButton>
        </Toolbar>
      </ProductTableContext.Provider>
      <VirtualizedTable
        items={sortedProducts}
        columns={columns}
        rowRenderer={RowRenderer}
      />
      {printing && (
        <PrintContainer onAfterPrint={() => setPrinting(false)}>
          <PrintTable columns={columns} items={sortedProducts} />
        </PrintContainer>
      )}
    </>
  );
}
